import xhr from './xhr';
/**
 * 预警管理相关的 API
 */
class CopyrightService {
	// 新增版权
	getCopyrightJs() {
		return xhr({
			method: 'post',
			url: '/bq/js',
		});
	}

	// 新增版权
	addCopyright(data) {
		return xhr({
			method: 'post',
			url: '/bq/add',
			params: data,
		});
	}

	// 版权列表
	getCopyrightList(data) {
		return xhr({
			method: 'post',
			url: '/bq/lb',
			params: data,
		});
	}

	// 版权类型列表
	getCopyrightTypes(data) {
		return xhr({
			method: 'post',
			url: '/bq/lx',
			params: data,
		});
	}

	// 删除版权
	deleteCopyright(data) {
		return xhr({
			method: 'post',
			url: '/bq/del',
			params: data,
		});
	}
}

// 实例化后导出，全局单例
export default new CopyrightService();
