<template>
  <div class="statistics-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item>
          <i class="icon-home"></i>知识产权
        </Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <section class="content-panel">
      <div class="search-box" v-if="isAdmin">
        <h2 class="search-title border-bottom">按条件搜索</h2>
        <div class="action-bar">
          <Button type="primary" @click="showModal = true">版权上传</Button>
        </div>
      </div>
      <div class="content-box">
        <h2 class="copyright-title">康一生科技</h2>
        <Carousel v-model="carouselIndex" dots="none" autoplay loop>
          <CarouselItem v-for="(item, index) in images" :key="index">
            <div class="swiper-image">
              <img :src="item.img" alt="swiper" />
            </div>
          </CarouselItem>
        </Carousel>
        <div class="desc-text" v-html="bqjs">
        </div>
        <div class="copyright-header">
          <span class="copyright-header-text">知识产权</span>
          <Select
            clearable
            v-model="selectedType"
            placeholder="请选择类型"
            style="width: 200px;"
            @on-change="getCopyrightList"
          >
            <Option :value="item.id" v-for="item in types" :key="item.id">{{ item.mc }}</Option>
          </Select>
        </div>
        <div class="copyright-panel">
          <div
            class="copyright-item"
            @click="handleEdit(item)"
            v-for="item in copyrights"
            :key="item.id"
          >
            <div class="copyright-img-panel" @click="previewImage(item.url)">
              <img class="copyright-img" :src="item.url" />
            </div>
            <div class="copyright-name">{{ item.bqmc }}</div>
            <div class="copyright-desc">{{ item.bqnr }}</div>
          </div>
        </div>
      </div>
    </section>
    <Modal
      title="版权上传"
      v-model="showModal"
      class-name="vertical-center-modal"
      width="800"
      @on-visible-change="handleModalVisible"
    >
      <Row class="modal-row">
        <Col span="4" class="modal-label">
          <div class="card-title">展示序号</div>
        </Col>
        <Col span="20">
          <Input-number :min="1" v-model="params.zsxh" style="width: 200px;"></Input-number>
        </Col>
      </Row>
      <Row class="modal-row">
        <Col span="4" class="modal-label">
          <div class="card-title">版权图片</div>
        </Col>
        <Col>
          <Upload
            action="/api/check/photo/upload"
            name="photo"
            :show-upload-list="false"
            :max-size="1024"
            :format="['png', 'jpg', 'jpeg']"
            :on-format-error="handleFormatError"
            :on-success="handleUploadSuccess"
            :on-exceeded-size="handleMaxSize"
          >
            <Button icon="ios-cloud-upload-outline">上传</Button>
          </Upload>
          <img v-if="imagePath" :src="imagePath" class="modal-img" />
        </Col>
      </Row>
      <Row class="modal-row">
        <Col span="4" class="modal-label">
          <div class="card-title">版权名称</div>
        </Col>
        <Col span="20">
          <Input v-model="params.bqmc" placeholder="请输入..." style="width: 200px;"></Input>
        </Col>
      </Row>
      <Row class="modal-row">
        <Col span="4" class="modal-label">
          <div class="card-title">版权类型</div>
        </Col>
        <Col span="20">
          <Select clearable v-model="params.bqlx_id" placeholder="请选择" style="width: 200px;">
            <Option :value="item.id" v-for="item in types" :key="item.id">{{ item.mc }}</Option>
          </Select>
        </Col>
      </Row>
      <Row class="modal-row">
        <Col span="4" class="modal-label">
          <div class="card-title">版权内容</div>
        </Col>
        <Col span="20">
          <Input type="textarea" :rows="3" v-model="params.bqnr" placeholder="请输入..."></Input>
        </Col>
      </Row>
      <div slot="footer">
        <template v-if="isAdmin">
          <Button type="error" @click="deleteCopyright" v-if="params.id">删除</Button>
          <Button type="primary" @click="addCopyright">保存</Button>
        </template>
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import VTitle from 'components/topHeader/topHeader';
import copyrightService from '@/services/copyrightService';

export default {
	components: { VTitle },
	data() {
		return {
			carouselIndex: 0,
			images: [
				{ img: new URL('/src/assets/img/swiper-1.png', import.meta.url).href },
				{ img: new URL('/src/assets/img/swiper-2.jpg', import.meta.url).href },
				{ img: new URL('/src/assets/img/swiper-3.png', import.meta.url).href },
				{ img: new URL('/src/assets/img/swiper-4.png', import.meta.url).href },
				{ img: new URL('/src/assets/img/swiper-5.png', import.meta.url).href },
			],
			copyrights: [],
			types: [],
			selectedType: '',
			showModal: false,
			params: {
				bqmc: '',
				bqlx_id: '',
				bqnr: '',
				zsxh: null,
				photo_id: '',
			},
			imagePath: '',
			isAdmin: '',
			bqjs: '',
		};
	},
	mounted() {
		this.getCopyrightTypes();
		this.getCopyrightList();
		this.getCopyrightJs();
		this.isAdmin = JSON.parse(localStorage.getItem('loginInfo')).username === 'boss';
	},
	methods: {
		getCopyrightJs() {
			copyrightService.getCopyrightJs().then((data) => {
				this.bqjs = data['nr'];
			});
		},
		getCopyrightList(val) {
			const params = { bqlx_id: val };
			copyrightService.getCopyrightList(params).then((data) => {
				this.copyrights = data;
			});
		},
		getCopyrightTypes() {
			copyrightService.getCopyrightTypes({ show_num: 1 }).then((data) => {
				this.types = data;
			});
		},
		addCopyright() {
			if (this.params.id) {
				this.params.jl_id = this.params.id;
			}
			copyrightService.addCopyright(this.params).then(() => {
				this.$Message.success('保存成功');
				this.showModal = false;
				this.getCopyrightList();
			});
		},
		handleMaxSize(file) {
			this.$Message.warning('文件 ' + file.name + ' 太大，文件大小不能超过 1M。');
		},
		handleFormatError(file) {
			this.$Message.warning(
				'文件 ' + file.name + ' 格式不正确，请上传 png、jpg 或 jpeg 格式的文件。',
			);
		},
		handleUploadSuccess(response) {
			if (response.c === 0) {
				this.imagePath = response.data.photo;
				this.params.photo_id = response.data.id;
			} else {
				this.$Message.error('上传失败，请重试');
			}
		},
		handleModalVisible(val) {
			if (!val) {
				this.params = {
					bqmc: '',
					bqlx_id: '',
					bqnr: '',
					zsxh: null,
					photo_id: '',
				};
				this.imagePath = '';
			}
		},
		previewImage(url) {
			event.stopPropagation();
			window.open(url, '_blank');
		},
		handleEdit(val) {
			this.showModal = true;
			this.params = val;
			this.imagePath = val.url;
		},
		deleteCopyright() {
			copyrightService.deleteCopyright({ jl_id: this.params.id }).then(() => {
				this.showModal = false;
				this.getCopyrightList();
			});
		},
	},
};
</script>

<style lang="less" scoped>
.content-panel,
.action-bar,
.content-box {
  padding: 15px;
}
.copyright-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
}
.swiper-image {
  height: 300px;
  margin-bottom: 15px;
  padding-top: 42.7%;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.desc-text {
  margin-bottom: 30px;
  font-size: 18px;
  .desc-body {
    text-indent: 2em;
  }
}
.copyright-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 15px;
}
.copyright-header-text {
  font-size: 16px;
  background-color: #ededed;
  padding: 6px 18px;
}
.copyright-panel {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  .copyright-item {
    width: calc(14% - 25px);
  }
  .copyright-img-panel {
    width: 100%;
    padding-top: 133.33%;
    position: relative;
    cursor: pointer;
  }
  .copyright-img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .copyright-name {
    font-size: 16px;
    text-align: center;
    margin: 10px 0;
  }
  .copyright-desc {
    color: #aaa;
    font-size: 16px;
  }
}
.modal-row {
  margin-bottom: 15px;
}
.modal-label {
  height: 32px;
  line-height: 32px;
}
.modal-img {
  height: 120px;
  width: 120px;
  object-fit: cover;
  margin-top: 15px;
}
</style>
